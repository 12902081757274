<template>
    <div class="p-pegas">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'">
                                {{ $t("message.vacation_graphic") }}
                            </div>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <crm-input
                                        :size="'small'"
                                        :className="'w100'"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        v-model="filterForm.search"
                                        :icon="'el-icon-search'"
                                    ></crm-input>
                                </div>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12" class="flex-style text-right">
                        <crm-create-and-column-settings
                            :permission="$options.name"
                            @c-create="drawerCreate = true"
                            :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                            :columns="columns"
                            @c-change="updateColumn"
                            :v_can_create="'vacation_graphic.create'"
                        >
                        </crm-create-and-column-settings>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div class="card-table-header table-crm-smart">
                <table
                    class="table-my-code table-bordered"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                    v-loading="loadingData"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>

                            <th v-if="columns.staff.show">
                                {{ columns.staff.title }}
                            </th>
                            <th v-if="columns.branch.show">
                              {{ columns.branch.title }}
                            </th>
                            <th v-if="columns.department.show">
                              {{ columns.department.title }}
                            </th>
                            <th v-if="columns.position.show">
                              {{ columns.position.title }}
                            </th>
                            <th v-if="columns.peryot_year.show">
                              {{ columns.peryot_year.title }}
                            </th>
                            <th v-if="columns.peryot_date.show">
                              {{ columns.peryot_date.title }}
                            </th>
                            <th v-if="columns.created_at.show">
                              {{ columns.created_at.title }}
                            </th>

                            <th v-if="columns.updated_at.show">
                                {{ columns.updated_at.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.staff.show">
                              <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.id"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></el-input>
                            </th>

                            <th v-if="columns.staff.show">
                              <select-staff
                              :class="mode ? 'filter__day' : 'filter__night'"
                              :size="'mini'"
                              v-model="filterForm.staff_id"
                            ></select-staff>
                            </th>
                            <th v-if="columns.branch.show">
                              <select-branch
                                  :placeholder="columns.branch.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.branch_id"
                                >
                              </select-branch>
                            </th>
                            <th v-if="columns.department.show">
                              <select-department
                                  :placeholder="columns.department.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.department_id"
                                >
                              </select-department>
                            </th>
                            <th v-if="columns.position.show">
                              <select-position
                                  :placeholder="columns.position.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.position_id"
                                >
                              </select-position>
                            </th>
                            <th v-if="columns.peryot_year.show">
                              <!-- <el-date-picker
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  class="w-100"
                                  size="mini"
                                  v-model="filterForm.month"
                                  type="month"
                                  :format="'MMMM yyyy'"
                                  :value-format="'MM.yyyy'"
                                  :placeholder="$t('message.monthly')"
                                >
                               </el-date-picker> -->
                            </th>
                            <th v-if="columns.peryot_date.show">
                            
                            </th>
                            <th v-if="columns.created_at.show">
                                  <crm-date-picker
                                    :placeholder="columns.created_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.created_at"
                                ></crm-date-picker>
                            </th>
                            <th v-if="columns.updated_at.show">
                                <crm-date-picker
                                    :placeholder="columns.updated_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.updated_at"
                                ></crm-date-picker>
                            </th>
                            <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th>
                        </tr>
                    </thead>
                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="vacationGraphic in list"
                            :key="vacationGraphic.id"
                            class="cursor-pointer"
                        >
                            <td v-if="columns.id.show">{{ vacationGraphic.id }}</td>

                            <td v-if="columns.staff.show">
                                {{ vacationGraphic.staff.name }} {{ vacationGraphic.staff.last_name }}
                            </td>
                            <td v-if="columns.branch.show">
                              {{ vacationGraphic.staff&&vacationGraphic.staff.department&&vacationGraphic.staff.department.branch?vacationGraphic.staff.department.branch.name:'' }}
                            </td>
                            <td v-if="columns.department.show">
                              {{ vacationGraphic.staff&&vacationGraphic.staff.department?vacationGraphic.staff.department.name:'' }}
                            </td>
                            <td v-if="columns.position.show">
                              {{ vacationGraphic.position.name }}
                            </td>
                            <td v-if="columns.peryot_year.show">
                              {{ formatDate(vacationGraphic.vacation_month_from) }} - {{ formatDate(vacationGraphic.vacation_month_to) }}
                            </td>
                            <td v-if="columns.peryot_date.show">
                              {{ formatPeryotDate(vacationGraphic.from_date) }} - {{ formatPeryotDate(vacationGraphic.to_date) }}
                            </td>
                            <td v-if="columns.created_at.show">
                                {{ vacationGraphic.created_at }}
                            </td>

                            <td v-if="columns.updated_at.show">
                                {{ vacationGraphic.updated_at }}
                            </td>

                            <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                                <crm-settings
                                    :name="$options.name"
                                    :model="vacationGraphic"
                                    :permissionShow="'vacation_graphic.update'"
                                    :permissionDestroy="'vacation_graphic.destroy'"
                                    :actions="actions"
                                    @edit="edit"
                                    @delete="destroy"
                                ></crm-settings>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                    ></crm-pagination>
                </div>
            </div>

            <div class="app-modal app-modal__full">
                <el-drawer
                    :with-header="false"
                    :wrapperClosable="false"
                    :visible.sync="drawerCreate"
                    size="70%"
                    ref="drawerCreate"
                    @opened="drawerOpened('drawerCreateChild')"
                    @closed="drawerClosed('drawerCreateChild')"
                  >
                    <crm-create
                        ref="drawerCreateChild"
                        drawer="drawerCreate"
                    ></crm-create>
                </el-drawer>

                <el-drawer
                    :with-header="false"
                    :wrapperClosable="false"
                    :visible.sync="drawerUpdate"
                    size="70%"
                    ref="drawerUpdate"
                    @opened="drawerOpened('drawerUpdateChild')"
                    @closed="drawerClosed('drawerUpdateChild')"
                >
                    <crm-update
                        :selectedItem="selectedItem"
                        ref="drawerUpdateChild"
                        drawer="drawerUpdate"
                    ></crm-update>
                </el-drawer>
            </div>
        </div>
    </div>
</template>
<script>
import list from "@/utils/mixins/list";
import moment from 'moment';
import selectStaff from "@/components/filters/inventory/select-staff";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
    name: "RegionController",
    mixins: [list],
    components: {
        CrmCreate,
        CrmUpdate,
        selectStaff
    },

    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            list: "vacationGraphic/list",
            columns: "vacationGraphic/columns",
            pagination: "vacationGraphic/pagination",
            statues: "vacationGraphic/statues",
            filter: "vacationGraphic/filter",
            sort: "vacationGraphic/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "vacationGraphic/index",
            setPagination: "vacationGraphic/setPagination",
            updateSort: "vacationGraphic/updateSort",
            updateFilter: "vacationGraphic/updateFilter",
            updateColumn: "vacationGraphic/updateColumn",
            updatePagination: "vacationGraphic/updatePagination",
            show: "vacationGraphic/show",
            empty: "vacationGraphic/empty",
            delete: "vacationGraphic/destroy",
            refreshData: "vacationGraphic/refreshData",
        }),
        formatDate(value){
          if (value) {
            return moment((value)).lang("ru").format('MMMM YYYY')
          }
        },
        formatPeryotDate(value){
          if (value) {
            return moment((value)).lang("ru").format('DD.MM.YYYY')
          }
        }
    },
};
</script>

